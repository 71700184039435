.ln-c-table tr:hover {
  background-color: #f5f5f5;
}
.top {
  display: flex;
  justify-content: space-between;
}
.topbar {
  display: flex;
  justify-content: space-between;
  background-color: #f06c00;
  /* border-Radius: 10px; */
  /* height: 50px; */
}
.topbar h5 {
  color: #f5f5f5;
  font-weight: 300;
  padding-left: 10px;
  margin-top: 10px;
}

h3 {
  /*background-color: #f06c00 !important;*/
  font-size: 1.625rem !important;
  line-height: 1.23077 !important;
  padding: 10px;
  /* border-radius: 5px; */
  color: #000000;
}

.content {
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: row;
}
.ln-c-input-group--has-action {
  margin-top: 5px !important;
  margin-right: 3px !important;
}
.left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
}

.right {
  display: flex;
  flex-direction: column;
  width: 40%;
  /* margin-top:20px!important;
    margin-right:20px;*/
}

.linksContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.quickLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 800px;
  /* text-align: center; */
  /* padding-left: 20px; */
}
.quickLinks a,
.quickLinks button {
  border: 1px solid #f06c00;
  padding: 20px;
  margin-left: 10px;
  text-align: center;
  width: 400px;
}
.qlinks h3 {
  text-align: center;
}
